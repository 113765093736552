@import "scss/base.scss";
@import "scss/tables.scss";

.rejectButton{
    border: none;
    background: none;
}

.questionDiv {
    width: $full;
    flex-direction: row;
    margin-top: $margin-10;
    &.documentUpload {
        align-items: start;
        .rejectButton {
            margin-top: $margin-6;
        }
    }
}

.valueColorClass {
    color: $color-black;
}

.placeholderColorClass {
    color: $color-input-placeholder;
}