@import "scss/base.scss";
@import "scss/tables.scss";

$dropdown-content-max-height: 48px;

.question {
    display: flex;
    margin-bottom: $margin-10;
    box-sizing: border-box;
    padding: $no-padding $padding-10;
    height: $dropdown-content-max-height;
}

.questionRow {
    display: flex;
    flex-direction: row;
    margin-bottom: $margin-5;
}

/* Media Queries */
// Mobile + Tablet
@media only screen and (max-width: $tablet-max-breakpoint) {
    .questionRow{
        display: flex;
        flex-wrap: wrap;

        .question {
            width: 100% !important;
        }
    }
}

// Desktop
@media only screen and (min-width: $desktop-min-breakpoint) {
   
}