@import "scss/tables.scss";

$placeholder-height: 20px;
$cell-height: 160px;
$information-width: 40%;
$information-mobile-width: 90%;
$lawyer-width: 25%;
$analyst-width: 27%;
$header-width: 200px;
$first-cell-width: 20%;
$second-cell-width: 75%;
$client-table-min-height: 250px;
$questionnaire-modal-height: 80%;
$questionnaire-modal-max-width: 1000px;
$questionnaire-modal-mobile-width: 90%;
$questionnaire-modal-mobile-height: 90%;

.applicationInfo {
    margin-top: $margin-10;

    h2 {
        @include pageHeader();
        margin-top: $margin-medium;
    }
    @include pageHeader();
}

.clientTableContainerWrapper {
    @include pageContentWrapper();
    min-height: $client-table-min-height;
}

.clientTableContainer {
    @include scrollableContent();
}

.filterButtons {
    @include filterButtons();
}

.clientTable {
    width: $full;
    border-collapse: collapse;
}

.infoRow {
    display: flex;
    flex-direction: row;
    margin-bottom: $margin-medium;
}

.questionnairesTable {
    border-collapse: separate;
    border-spacing: 0 $spacing-small;
}

.information {
    min-height: $cell-height;
    border-radius: $border-radius-round-slight;
    margin-right: $margin-10;
    background: linear-gradient($color-background, $color-background) padding-box,
                var(--blue-yellow-gradient) border-box;
    border-radius: $border-radius-round-slight;
    border: $border-size-slightly-big solid transparent;
    flex-basis: 50%;
    flex-grow: 0;
    padding: $padding-20 $padding-30 $no-padding $padding-30;
}

.headerName {
    font-size: $font-size-large;
    font-weight: $font-weight-bolder;
    margin-bottom: $margin-15;
}

.clientHeaderName {
    font-size: $font-size-large;
    font-weight: $font-weight-bolder;
    margin-bottom: $margin-25;
}

.subName {
    font-size: $font-size-smallerText;
    color: $color-text-subname;
}

.answer {
    margin-bottom: $margin-20;
}


.lawyerInfo {
    min-height: $cell-height;
    position: relative;
    border-radius: $border-radius-round-slight;
    margin-right: $margin-10;
    background: linear-gradient($color-background, $color-background) padding-box,
                var(--blue-yellow-gradient) border-box;
    border-radius: $border-radius-round-slight;
    border: $border-size-slightly-big solid transparent;
    flex-basis: 25%;
    flex-grow: 0;
    padding: $padding-20 $padding-15 $no-padding $padding-15;
}

.analyst {
    min-height: $cell-height;
    position: relative;
    border-radius: $border-radius-round-slight;
    background: linear-gradient($color-background, $color-background) padding-box,
                var(--blue-yellow-gradient) border-box;
    border-radius: $border-radius-round-slight;
    border: $border-size-slightly-big solid transparent;
    flex-basis: 25%;
    flex-grow: 0;
    padding: $padding-20 $padding-15 $no-padding $padding-15;
}

.questionnaireModal {
    width: $modal-width;
    max-width: $questionnaire-modal-max-width;
    height: $questionnaire-modal-height;
    position: fixed;
    top: $half;
    left: $half;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
}

/* Media Queries */
// Desktop
@media only screen and (min-width: $tablet-max-breakpoint) {
    .clientTable {
        td:first-child {
            width: $first-cell-width;
        }
        
        td:nth-of-type(2) {
            width: $second-cell-width;
            color: $color-progress-bar-grey;
            opacity: $color-progress-bar-opacity;
        }
    }
}

// Mobile
@media (max-width: $tablet-max-breakpoint) {

    .lawyerInfo {
        display: $hide;
    }

    .analyst {
        display: $hide;
    }

    .information {
        min-height: $cell-height;
        margin-right: $no-margin;
        flex-basis: 100%;
        padding: $padding-10 $padding-20 $padding-5 $padding-20;

        .headerName {
            margin-bottom: $margin-10;
        }

        .answer {
            margin-bottom: $margin-5;
            font-size: $font-size-slightly-smallText;
        }
    }
    .filterButtons {
        flex-wrap: wrap;
        row-gap: $margin-10;
        
        .newAppButton, .filter, .sort {
            flex: 1;
        }
        .searchBar {
            width: $full;
        }
    }

    .newApplication {
        width: $questionnaire-modal-mobile-width;
        height: $questionnaire-modal-mobile-height;
    }

    .applicationInfo {
        display: $hide;
    }

    .dropDownDirectionClass {
        right: 0;
    }
}