@import "scss/base.scss";
@import "scss/tables.scss";

$desktop-button-width: 20%;
$mobile-button-width: 30%;
$document-modal-min-height: 30%;
$document-modal-height: 80%;
$document-modal-left: 10%;

.form {
    display: flex;
    flex-direction: column;
    height: $full;
    width: $full;
    margin: $no-margin;
}

.questionsContainer {
    flex-grow: 1; 
    overflow-y: auto;
    overflow-x: hidden;
    margin-right: $margin-2;
}

.questionGridRow{
    display: flex;
    flex: 1;
}

.subtitle {
    color: $color-header-text-grey;
}

.submitError{
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-form-error;    
}

.questionnaireJSONErrorStyle {
    height: $full;
    width: $full;
    margin: auto;
    margin-bottom: $margin-60;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: $font-size-large;
}

.documentModal {
    width: $modal-width;
    height: $document-modal-height;
    min-height: $document-modal-min-height;
    margin-left: $document-modal-left;
    margin-top: $margin-50;

    h2 {
        font-size: $font-size-slightly-large;
    }

    img {
        width: $full;
        height: $full;
    }

    .modalContent {
        margin-left: $margin-10;
        height: 90%;
        overflow: auto;
        overflow-wrap: break-word;
        .contentRow {
            width: $full;
            border-bottom: $border-size solid $color-grey;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.modalHeader {
    display: flex;
    margin-left: $margin-10;
    margin-bottom: $margin-10;
}

.exitButton {
  color: $color-blue;
  margin: $no-margin;
  cursor: pointer;
}

.errorModal{
    height: $full;
    display: flex;
    flex-direction: column;
    align-items: end;
}

// CLIENT
.confirmationOaths {
    display: flex;
    flex-direction: column;
    margin: $margin-10;
}

.buttonContainer {
    display: flex;
    justify-content: center;
    width: $full; 
    margin-bottom: $margin-40;

    button {
        min-width: fit-content;
        width: $mobile-button-width;
    }

    .userSaveButton {
        margin-left: 100px;
    }
    .userSubmitButton {
        margin-right: 100px;
    }

    .reviewerSaveButton {
        margin-left: $margin-20;
    }
    .reviewerSubmitButton {
        margin-right: $margin-40;
    }
}

.checkmarkDiv{
    margin-top: $margin-5;
  }

/* Media Queries */
// Mobile + Tablet
@media only screen and (max-width: $tablet-max-breakpoint) {
    
}

// Desktop
@media only screen and (min-width:$desktop-min-breakpoint) {
    
    .reviewerSaveButton,
    .reviewerSubmitButton {
        max-width: $desktop-button-width;
    }
}