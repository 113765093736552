@import "scss/base.scss";

$checkbox-width: 20px;
$checkbox-height: 20px;
$checkbox-min-height: 55px;

.checkboxContainer {
  display: flex;
  flex-direction: column;
  align-items: start;
  overflow: hidden;
  &:hover {
    background-color: $color-background-hover;
  }
  
  .label {
    position: relative;
    padding-left: $padding-35;
    display: flex;
    flex-direction: row;
  
    .checkboxInput {
      opacity: 0;
      position: absolute;
  
      &:checked + span {
        &:before {
          content: '\2713'; // Checkmark character
          font-size: $font-size; 
          font-weight: $font-weight-bolder;
          color: $color-text; 
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  
    .customCheckbox {
      position: absolute;
      top: calc($half - $checkbox-height / 2 - $border-size-slightly-big);
      left: 0;
      width: $checkbox-width;
      height: $checkbox-height; 
      border: $border-size-slightly-big solid $color-input-border; 
      background-color: transparent; 
      border-radius: $border-radius-slightly-round; 
    }

    .text {
      color: $color-input-dark;

      &.redText {
        color: $color-alert;
      }
    }
  }
  
  .alert {
      color: $color-alert;
      font-size: $font-size-smallText;
  }

  .errorDiv{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .errorBorder {
    border-color: $color-alert !important;  
  }
}