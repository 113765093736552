@import "scss/base.scss";
@import "scss/tables.scss";

$row-button-height: 30px;
$row-button-width: 30px;
$table-header-height: 25px;
$questionnaire-table-width: 150px;
$table-header-padding: 13px; // To align table headers with table rows like in the mockup
$dropdown-content-max-height: 48px;
$table-data-height: 50px;
$table-data-height-mobile: 45px;
$submit-button-width: 30%;


.questionnaireTable {
  min-width: $questionnaire-table-width;
  width: $full;
  overflow-x: auto;
  table-layout: fixed;

  thead {
    width: $full;
    height: $table-header-height;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;

    tr:last-child {
      border-bottom: $border-size solid $color-border;
  }
  }

  .tableHeader {
    white-space: nowrap;
    color: $color-text-black;
  }

  .tableHeaderText {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .headerColumn{
    display: flex;
    flex-direction: row;
    padding-left: $padding-20;
    .rowButtonPadder {
      padding: $no-padding;
      min-width: calc($row-button-width * 2);
      max-width: calc($row-button-width * 2);
    }
  }

  tr {
    text-align: left;
    width: $full;
    border: $hide;
    vertical-align: middle;
  }

  td {
    display: flex;
    justify-content: center;
    height: $table-data-height;
  }

  th {
    width: $full;
    border: $hide;
  }

  .rowButtonSection {
    height: $table-data-height;
    display: flex;
    margin-top: $margin-10;
    padding: $no-padding;
    padding-left: $padding-5;
    flex-direction: row;
    justify-content: left;
    align-items: center;

    // unlocked (edit & delete)
    .rowButton {
      display: flex;
      height: $row-button-height;
      width: $row-button-width;
      margin: $no-margin;
      margin-right: $margin-5;
      justify-content: center;
      align-items: center;
      background: none;
      border-radius: $border-radius-very-round;
      border-width: $border-size;
      border-color: $color-grey;
      border-style: solid;
      box-shadow: none;
      overflow: hidden;
      cursor: pointer;
    }

    // locked (view)
    .viewButton {
      font-weight: $font-weight-bold;
    }

    &::before, 
    &::after {
      display: $hide;
    }
  }

  .rowSection{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: $padding-4 0 $padding-12 $padding-15;
    
    &:hover {
      cursor: pointer;
      border-radius: $border-radius-round-slight;
      background-color: $color-background-hover;
      
    }
  }
}

.buttonDiv {
  display: flex;
  justify-content: center;
  width: $full; 
  margin-bottom: $margin-50;
}

.submitButton {
  width: $submit-button-width; 
  height: fit-content;
}

// TODO: This should be a shared style
// TODO: See note about overriding mixins for the formInput mixin
.locked {
  border-color: $color-input-placeholder;
}

// TODO: This should be a shared style
.unlocked {
  pointer-events: auto; 
}

/* Media Queries */
// Mobile + Tablet
@media only screen and (max-width: $tablet-max-breakpoint) {
  .questionnaireTable {
    .headerColumn {
      padding-left: $padding-15;
    }
    
    .rowButtonSection{
      display: none !important;
    }

    .rowSection{
      height: $table-data-height-mobile;
      margin: $margin-5 $no-margin;
    }
  }

  .mobileBarRow {
    height: $border-size !important;
    padding: $no-padding !important;
    margin: $no-margin !important;
    border: none !important;
  
    td {
      background-color: $color-light-grey !important;
      height: $border-size !important;
      padding: $no-padding !important;
      margin: $no-margin !important;
      border: none !important;
    }
  }
}

// Desktop
@media only screen and (min-width: $desktop-min-breakpoint) {
  .questionnaireTable {   
    .rowSection{
      
      &:hover {
        cursor: pointer;
        border-radius: $border-radius-round-slight;
        background-color: $color-background-hover;
        
        // Ensures all fields within a row get highlighted, when hovering over a rowSection 
        td > div { 
          cursor: pointer;
          background-color: $color-background-hover;
          border-radius: $border-radius-round-slight;
          
          /* 
           * Allows the Edit Modal to open up when any field in a rowSection is clicked.
           * Also prevents each individual field from getting highlighted when each one is hovered over.
          */
          pointer-events: none;
        }
      }
    }
  }
}