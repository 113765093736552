@import "scss/tables.scss";

$cell-height: 50px;
$status-height: 20px;
$progress-bar-width: 40%;
$progress-bar-height: 16px;
$progress-bar-top: 4px;

.approved {
    background-color: $color-file-status-closed;
    width: $status-bubble-width;
}

.pendingReview {
    background-color: $color-int-status-pending-review;
    width: $status-bubble-width;
}

.getStarted {
    background-color: $color-int-status-get-started;
    width: $status-bubble-width;
}

.active {
    background-color: $color-int-status-active;
    width: $status-bubble-width;
}

.rejected {
    background-color: $color-int-status-rejected;
    width: $status-bubble-width;
}

.progressBar {
    position: relative;
    top: $progress-bar-top;
    width: $progress-bar-width;
    height: $progress-bar-height;
    border-radius: $border-radius-very-round;
    background-color: $color-progress-bar-grey;
    opacity: $color-progress-bar-opacity;
    margin-left: $margin-60;
    margin-right: $margin-10;
    overflow: hidden;
    float: left;
}

.progressFill {
    height: $full;
    border-radius: $border-radius-very-round;
    background-color: $color-blue; 
    width: 0; 
}

/* Media Queries */
// Mobile + Tablet
@media only screen and (max-width: $tablet-max-breakpoint) {
    .lawyer, .cases {
        display: $hide;
    }
    .headers {
        display: $hide;
    }
    .textTitle{
        text-align: left;
    }
    .statusApproved, .statusPending, .statusInProgress, .statusActive, .statusGetStarted, .statusRejected {
        float: right;
    }
    
}

// Desktop
@media (min-width: $desktop-min-breakpoint) {
    .status {
        margin-left: auto;
    }
    .mobileStatus {
        display: $hide;
    }
}