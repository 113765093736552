@import "scss/base.scss";

$file-upload-height: 50px;
$blue-bubble-height: 40px;

.fileUploadContainer {
    min-height: $file-upload-height;
}

.customFileUpload {
    @include formInputStyle();
    display: inline-block;
    font-size: $font-size-slightly-smallText;
    cursor: pointer;
    text-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.primary {
        padding: $padding-10;
        padding-top: $padding-13;
        padding-right: $padding-40;
        background-image: url('/components/icons/svgs/paper-clip.svg');
        background-repeat: no-repeat;
        background-position: right 10px center; 
        background-size: 12px;
    
        &:hover {
            background-color: $color-background-hover;
        }
    }

    &.blueBubble {
        justify-content: center;
        color: $color-white;
        background-color: $color-upload-button-background;
        font-size: $font-size-slightly-smallText;
        padding-top: 7px;
        padding-left: $padding-15;
        padding-right: $padding-20;
        border-radius: $border-radius-round;
        border: $hide;
        margin: $no-margin;
        min-height: $blue-bubble-height;
        width: fit-content;

        div, p, a, span {
          color: $color-button-text;
        }
    
        &:hover {
          background-color: $color-upload-button-hover;
        }

        &.locked {
            background-color: $color-button-background-disabled;
        }
    }

    &.errorBorder {
        border-color: $color-alert;  
    }
}

.fileError{
    margin-top: $margin-2;
    display: flex;
    justify-content: start;
}

.hiddenFileInput {
    opacity: 0;
    z-index: -1;
    display: $hide;
}

.fileNameContainer {
    width: $full;
}
  
.fileName {
    font-size: $font-size-smallerText;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $margin-2;
}

.clickableFileName {
    cursor: pointer;
    color: $color-blue;
}

.removeFileButton {
    background: none;
    border: none;
    cursor: pointer; 
    display: flex;
    align-items: center;
}

.alert {
    color: $color-alert;
    font-size: $font-size-smallerText;
} 

.valueColorClass {
    color: $color-black;
}

.placeholderColorClass {
    color: $color-input-placeholder;
}
