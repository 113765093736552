@import "scss/base.scss";

$img-size: 20px;
$label-top: 4px;

.wrapperDiv {
  width: $full;

  .inputDiv {
    width: $full;
    height: $full;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .dateContainer {
      @include formInputStyle();
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      padding: $no-padding $padding-medium;
      border-color: $color-input-border;
      height: $datepicker-height;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      border: $border-size solid $color-input-border;
      border-radius: $border-radius-round-slight 0 0 $border-radius-round-slight;
      background-color: transparent;
      min-height: $input-min-height;

      // TODO: This should be a shared style
      &.locked {
        border-color: $color-input-placeholder;
      }

      .lockedInputClass {
        color: $color-input-grey !important;
      }

      .populatedLockedField {
        color: $color-black !important;
      }

      .label {
        top: $label-top;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .dateText {
        height: fit-content;
        outline: none;
        font-size: $font-size-slightly-smallText;
        border: none;
        min-width: 0;
        padding: $no-padding;
        width: 100%;
        background-color: transparent;
        cursor: text;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &.errorBorder {
        border-color: $color-alert;
      }

      &:hover {
        background-color: $color-background-hover;
      }
    }

    .calendarIcon {
      height: 50px;
      box-sizing: border-box;
      padding-right: $margin-15;
      padding-left: $padding-10;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      border: $border-size solid $color-input-border;
      border-left: none;
      border-radius: 0 $border-radius-round-slight $border-radius-round-slight 0;

      &:hover {
        cursor: pointer;
        background-color: $color-background-hover;
      }

      &.errorBorder {
        border-color: $color-alert;
      }
    }
  }
}

.error {
  color: $color-alert;
  font-size: $font-size-smallText;
}

.errorText {
  color: $color-alert !important;
}

/* Media Queries */
// Mobile + Tablet
@media only screen and (max-width: $tablet-max-breakpoint) {
  .tableQuestionMobileStyle {
    .dateContainer {
      border: none;
      background-color: inherit; 
      color: inherit; 
      border: none;
      padding: $no-padding;
    }

    .calendarIcon {
      display: none;
    }
  }
}

// Desktop
@media only screen and (min-width: $desktop-min-breakpoint) {
   
}