/*
Try to utilize variables as much as possible.  It's one of the best things about a CSS preprocessor like Sass and it makes updates much easier
*/

//theme defaults
$color-blue: #2A3192;
$color-grey: #dadbdd;
$color-light-grey: #F1F1F1;
$color-yellow: #F6D27B;
$color-orange: #F19473;
$color-grey-1: #666666;
$color-grey-2: #999999;
$color-light-blue: #cfd3d4;

$color-alert: #F57E77;
$color-success: #b1df95;
$color-stark-white: #ffffff;
$color-white: #eaeaea;
$color-form-error: #D90038;

//  colour background
$color-background: #ffffff;
$color-background-secondary: #F0793F;
$color-highlight: #b1df95;
$color-box-shadow: #888888;
$color-background-footer: #b1df95;
$color-background-white-secondary: #F7F7F8;
$color-background-hover: rgba(42, 49, 146, 0.10);
$color-background-hover-secondary: rgb(230, 231, 247);
$color-background-tooltip: #eaebf9;

// colour text
$color-text: #787878;
$color-input-grey: #b8bbbc;
$color-text-secondary: #ffffff;
$color-text-link: #ffc7aa;
$color-text-link-hover: #d1d1d1;
$color-text-disabled: #787878;
$color-text-black: #030229;
$color-black: #000000;
$color-text-subname: #4F5B67;
$color-text-loading: #455A64;

// inputs
$color-input-white: #FAFAFA;
$color-input-dark: #5E6366;
$color-input-opacity: 0.7;
$color-input-placeholder: #ABAFB1;

// buttons
$color-button-background: $color-blue;
$color-submit-button-background: #2A3192;
$color-button-background-disabled: #2A31923D;
$color-button-text: $color-white;
$color-upload-button-background: #4040F2;
$color-upload-button-hover: rgba($color-upload-button-background, 0.75);

$color-button-background-disabled: #c7c7c7;
$color-table-text-disabled: #f0f0f0;
$color-table-disabled-background: #a0a0a0;

// borders:
$color-border: #E5E5E5;
$color-help-border: #A8B0B9;
$color-input-border: #5E6366;

// modals
$color-modal-background: #d48e6a;
$color-modal-text: #ffffff;

// header
$color-header-border: #b1df95;
$color-menu-text: #551d00;
$color-menu-background: #ffffff;
$color-header-background-grey: #F0F2F5;
$color-header-text-grey: #727272;

// flashtext
$color-flash-from: #d48e6a;

// intStatus
$color-int-status-active: #2A933B;
$color-int-status-pending-review: #E86F00;
$color-int-status-get-started: #003FE2;
$color-int-status-approved: #2A3192;
$color-int-status-rejected: #C10000;

// fileStatus
$color-file-status-active: #2A933B;
$color-file-status-pending-review: #E86F00;
$color-file-status-submitted: #003FE2;
$color-file-status-closed: #2A3192;
$color-file-status-on-hold: #C10000;

// Dropdown
$dropdown-button: #006900;

// Progress bar
$color-progress-bar-grey: #C8C8D1;
$color-progress-bar-opacity: 100%;

:root {
    --color-blue: #{$color-blue};
    --color-yellow: #{$color-yellow};
    --blue-yellow-gradient: linear-gradient(to right, var(--color-blue), var(--color-yellow));
    --gradient-left: linear-gradient(to right, var(--color-blue), var(--color-yellow));
}
